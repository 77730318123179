.planned-order-table-search {
  margin-right: 15px;
}
.planned-order-table-search .ant-input-group .ant-input-group-addon button {
  background-image: url('../../assets/images/search.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 10px;
  background-color: #2170b8;
  border-color: #2170b8;
  padding: 4px 15px 4px 30px;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}
.planned-order-table-search .ant-input-group .ant-input-group-addon {
  background-color: #2170b8;
  border-radius: 0px 4px 4px 0;
}
.planned-order-table-search .ant-input {
  border: 1px solid #707070;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  line-height: 22px;
  border-radius: 4px;
}
.planned-order-table-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 4px 4px 0;
}

.sortby-selection {
  margin-right: 15px;
  padding: 0 0px 0 13px;
}

.create-proforma-cta {
  vertical-align: top;
  background-color: transparent;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #2170b8;
  border-radius: 4px;
}
