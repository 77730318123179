.channel-list-drawer {
  transform: none !important;
}

.channel-list-drawer .ant-drawer-close {
  padding-right: 0px !important;
}

.channel-list-drawer .str-chat__channel-search {
  padding: 20px 10px !important;
}

.channel-list-drawer
  .str-chat-channel-list
  .str-chat__channel-list-messenger__main {
  padding-top: 0px !important;
  height: 90% !important;
}

.chat-popup .str-chat {
  height: 50vh !important;
}

.chat-popup .ant-drawer-content {
  width: 25% !important;
  left: 75% !important;
}

.chat-popup .ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.chat-popup .ant-drawer-body {
  padding: 0px !important;
}

.chat-popup .ant-drawer-close {
  color: white !important;
  padding-right: 0px !important;
}

.chat-popup .str-chat__header-livestream {
  background-color: #2170b8 !important;
  color: white !important;
  border-radius: 0px !important;
}

.chat-popup .str-chat.messaging {
  background: none !important;
}

.chat-popup-holder .str-chat-channel-list {
  display: none;
}
