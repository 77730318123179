.plan-order-data-table .ant-table-thead > tr > th {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
}

.plan-order-data-edit {
  width: 2020px;
  min-width: 100%;
  table-layout: auto;
}

.edit-table-container {
  overflow-x: scroll;
  box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%);
  padding-bottom: 25px;
}

.plan-order-data-edit thead tr td {
  font-family: 'Roboto Condensed', sans-serif;
  padding: 10px 10px 10px 0;
}

.plan-order-data-edit tbody tr td {
  font-family: 'Roboto Condensed', sans-serif;
  padding: 10px 10px 10px 0;
}

.plan-order-data-edit tr td:first-child {
  width: 230px;
}
.plan-order-data-edit tbody tr td:first-child {
  color: #918f8f;
}
.plan-order-data-edit tr td {
  border-bottom: 1px solid #f0f0f0;
}

.plan-order-data-edit .table-input {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.plan-order-data-edit .table-input .ant-select-selector {
  background-color: #f4f5f8;
  border: 0;
}

.plan-order-data-edit .table-input::-webkit-input-placeholder {
  /* Edge */
  color: #231f20;
}

.plan-order-data-edit .table-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #231f20;
}

.plan-order-data-edit .table-input::placeholder {
  color: #231f20;
}

.plan-order-data-edit .table-input .ant-picker-suffix {
  color: #231f20cf;
}

.plan-order-data-edit .table-input .ant-select-arrow {
  color: #231f20;
}

.plan-order-data-edit
  .table-input
  .ant-picker-input
  input::-webkit-input-placeholder {
  /* Edge */
  color: #231f20;
}

.plan-order-data-edit
  .table-input
  .ant-picker-input
  input:-ms-input-placeholder {
  /* Edge */
  color: #231f20;
}

.plan-order-data-edit .table-input .ant-picker-input input::placeholder {
  /* Edge */
  color: #231f20;
}
.plan-order-data-table .ant-table-tbody > tr > td:first-child {
  color: #918f8f;
}
