.a1 {
  font-family: 'Roboto Condensed', sans-serif;
}
.a2 {
  font-family: 'Roboto Condensed', sans-serif;
}
.a3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.a4 {
  font-family: 'Helvetica LT Std, Bold';
}
.ant-typography {
  font-family: 'Roboto Condensed', sans-serif;
}
h1 {
  color: #231f20;
  font-size: 55px;
  line-height: 57px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
h2 {
  color: #231f20;
  font-size: 46px;
  line-height: 48px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
h3 {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
h4 {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
h5 {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.ant-typography.ant-typography-secondary {
  font-family: 'Roboto Condensed', sans-serif;
}
.white-font {
  color: white;
}
body,
.ant-layout {
  background: #f4f5f8;
}
.ca-divider {
  width: 50px;
  height: 5px;
  background: #fff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.carava-header {
  background: #fff;
  display: flex;
  width: 100%;
}
.carava-menu,
.carava-menu .ant-layout-sider-children {
  background: #231f20 !important;
}
.toggleicon {
  padding-top: 12px;
  padding-bottom: 65px;
  text-align: center;
  color: #fff;
  background: #231f20;
  font-size: 25px;
}
.carava-menu .ant-menu-item {
  padding: 0 10px !important;
}
.mb-15 {
  margin-bottom: 15px;
}
.ant-layout-sider-children {
  position: fixed;
}
.carava-menu .ant-menu-inline-collapsed .ant-menu-item {
  text-align: center;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 25px / 2);
}
.fl-right {
  float: right;
}
.fl-left {
  float: left;
}
.toggleicon img {
  cursor: pointer;
}
.ant-layout-sider {
  background: #231f20;
}
.carava-menu .ant-menu.ant-menu-dark {
  background: #231f20;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  font-size: 25px;
  color: #fff;
  opacity: 1;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 25px;
  color: #717070;
  margin-right: 12px;
  opacity: 0.4;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  font-size: 25px;
}
.carava-menu .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.carava-menu .ant-menu-dark .ant-menu-item-selected .anticon + span {
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
}
.color-blue {
  color: #2170b8;
}
.color-green {
  color: #36b44c;
}
.carava-menu
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #231f20;
}
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 20px;
}
.ant-menu-item .ant-menu-item-icon + span {
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
}
.carava-header .nav-right {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
}
.carava-header .nav-left {
  display: flex;
  margin-left: 20px;
}
.dash-logo {
  padding: 0 16px;
  width: 200px;
}
.dash-logo img {
  width: 100%;
}
.carava-searchbar .ant-input-group {
  border-radius: 8px;
  margin-top: 16px;
  overflow: hidden;
}
.carava-searchbar input {
  background: #f2f2f2;
  border-color: #f2f2f2;
  font-family: 'Roboto Condensed', sans-serif;
}
.nav-search-area {
  position: relative;
}
.nav-search-result-area {
  width: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  z-index: 999;
  max-height: 420px;
  overflow-y: scroll;
  box-shadow: 0px 8px 20px #0000001a;

  border-radius: 4px;
}
.nav-result-heading {
  font-size: 18px;
  line-height: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: capitalize;
  color: #918f8f;
}
.nav-result-box {
  display: inline-block;
  width: 100%;
  padding: 20px 15px;
  border-bottom: 1px solid #e3e3e3;
}
.nav-result-box:last-child {
  border-bottom: none;
}

.dark-input {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.carava-searchbar .ant-input-group-addon button {
  border: 0;
  background: #f2f2f2;
}
.carava-searchbar .ant-input:hover,
.carava-searchbar .ant-input:focus {
  border-color: #f2f2f2;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}
.carava-searchbar .anticon-search {
  color: #222;
}
img.chat-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.notification-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin: 0 15px;
}
.notification-menu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover,
.notification-menu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected {
  border: 0;
}
.carava-searchbar {
  margin-right: 30px;
}
.profile-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border: 0;
}
.heading-divider {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 15px;
}
.head-divi-box {
  display: inline-block;
}
.head-divi-line {
  background: #2170b8;
  margin-bottom: -3px;
  height: 6px;
  border-radius: 20px;
}
.head-divi-title {
  font-size: 20px;
  line-height: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.head-box-va {
  float: right;
  font-size: 16px;
  line-height: 25px;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  /* text-transform: capitalize; */
}
a.head-box-va {
  line-height: 35px;
}
.ca-card-light {
  background: #fff;
  padding: 20px 24px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 8px 20px #7090b024; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 8px 20px #7090b024; /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 8px 20px #7090b024;
}
.ca-card-dark {
  background: #231f20;
  padding: 20px 24px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 8px 20px #7090b024; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 8px 20px #7090b024; /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 8px 20px #7090b024;
}

.ant-table-thead > tr > th {
  font-family: 'Roboto Condensed', sans-serif;
  background: transparent;
  color: #918f8f;
  line-height: 16px;
  /* white-space: nowrap; */
  vertical-align: bottom;
}
.ant-table-tbody > tr > td {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.ant-table-footer {
  background: none !important;
}
.heading-badge {
  background: #bcd4ea;
  color: #2170b8;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  line-height: 30px;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  margin-left: 15px;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 14px;
}
.ant-table-filter-trigger {
  z-index: 99;
  font-size: 14px;
  color: #918f8f;
}
.fl-100 {
  float: left;
  width: 100%;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 14px;
  color: #918f8f;
}
.ant-table-column-sorter-inner {
  color: #918f8f;
}

/* .ant-table-filter-trigger-container{right: 10px;} */
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: transparent;
}
/* .ant-table-tbody > tr > td, .ant-table-thead > tr > th{padding: 10px 10px 10px 0} */
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px 4px;
}

.ant-table-column-sorters {
  padding: 10px 4px;
}

/* .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{padding: 10px 10px 10px 0;} */
.table-view-cta {
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  padding: 0px 10px;
}

.ant-table-filter-dropdown .ant-dropdown-menu-item span {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  color: #231f20;
}

.ant-switch-checked {
  background-color: #2170b8;
}

.ant-radio-inner {
  border-radius: inherit;
}
.ant-radio-inner::after {
  background-color: #2170b8;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #2170b8;
}
.ant-radio-inner {
  background-color: #d8e3ec;
  border-radius: 2px;
}
.ant-checkbox-checked:hover {
  border-radius: 4px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2170b8 !important;
  border-radius: 4px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #2170b8;
}
.ant-table-filter-dropdown-btns .ant-btn-link {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  color: #231f20;
}
.ant-table-filter-dropdown-btns .ant-btn-primary {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #2170b8;
  border-color: #2170b8 !important;
  border-radius: 2px;
  color: #fff;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 20px;
}
table tr td.ant-table-selection-column .ant-checkbox-inner,
.ant-checkbox-inner {
  /* background-color: #d8e3ec; */
  background-color: #fafafa;
  border-radius: 4px;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #d4d4d4 !important;
}
.heading-right-area {
  float: right;
}

.sortby-selection {
  background-color: #efefef;

  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  font-size: 12px;
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.sortby-selection .ant-select-selector {
  background: transparent !important;
  padding: 0 5px !important;
  border: 1px solid #efefef !important;
}
.sortby-selection .ant-select {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #2170b8;
}
.sortby-selection .ant-select {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #2170b8;
}
.sortby-selection .ant-select-arrow {
  color: #2170b8;
}
.heading-cta {
  vertical-align: top;
  background-color: #2170b8;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #2170b8;
  border-radius: 4px;
}
/* .heading-cta:hover {
  background-color: #2170b8;
  color: #fff;
  border: 1px solid C;
} */
.heading-cta-danger {
  vertical-align: top;
  background-color: #ef5869;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #ef5869;
  border-radius: 4px;
}
.heading-cta[disabled] {
  background: #d3e2f1;
  border-color: #d3e2f1;
  color: #fff;
}
.heading-cta-danger:hover {
  background-color: #ea1f37 !important;
  border-color: #ea1f37 !important;
  color: #fff;
}
.ant-table-pagination-right {
  justify-content: center;
}

.ant-pagination-item {
  border: 1px solid #2170b8;
}
.ant-pagination-item a {
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 14px;
}
.ant-pagination-item:hover {
  border-color: #2170b8;
  background: #2170b8;
}
.ant-pagination-item:hover a {
  color: #fff;
}
.ant-pagination-item-active {
  background: #2170b8;
}
.ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #2170b8;
}
.ant-pagination-prev .ant-pagination-item-link span svg,
.ant-pagination-next .ant-pagination-item-link span svg {
  color: #2170b8;
}
.ant-pagination-options
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #2170b8;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  /* font-family: 'Roboto Condensed', sans-serif; font-weight: 700; */
}
.ant-pagination-options .ant-select-show-arrow .ant-select-arrow {
  color: #2170b8;
}
.ant-table-pagination.ant-pagination {
  margin: 25px 0 16px 0;
}
.notification-alerts .ant-tabs-nav {
  margin-bottom: 5px;
}
.notification-alerts .ant-tabs-content-holder {
  max-height: 75vh;
  overflow-y: auto;
}

.notification-contain {
  width: 450px;
  padding: 15px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 20px #0000001a;
  box-shadow: 0px 8px 20px #0000001a;
  border-radius: 4px;
}
.tab-reminder-alert .reminder-list-desc {
  width: calc(100% - 120px);
}
.view-cta button:hover {
  background-color: #2170b8;
  color: #fff;
  border: 1px solid #2170b8;
}
.tab-reminder-alert .view-cta {
  width: 78px !important;
}
.notification-item {
  padding: 15px 5px !important;
}
.notification-item:hover {
  background: #fafafa !important;
  cursor: pointer;
}
.tab-notification-alert .view-cta {
  width: 75px !important;
}
.tab-notification-alert .reminder-list-desc {
  width: calc(100% - 75px);
  padding: 0 10px 0 0;
}
.tab-notification-alert .bid-value {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tab-notification-alert .view-cta p {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
}
.notification-alerts .ant-tabs-tab-btn {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700 !important;
  color: #d3d2d2;
}
.notification-alerts .ant-tabs-tab-btn .heading-badge {
  background: #eaf1f8;
  color: #bbd3e9;
}
.notification-alerts .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #231f20;
}
.notification-alerts
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn
  .heading-badge {
  background: #bcd4ea;
  color: #2170b8;
}
.notification-alerts .ant-tabs-ink-bar {
  background: #2170b8;
  height: 6px !important ;
  border-radius: 20px;
}
.notification-alerts .ant-tabs-tab {
  padding: 12px 0 20px 0;
}
.notification-alerts.ant-tabs-top > .ant-tabs-nav::before,
.notification-alerts.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 3px;
}

.h-100 {
  height: 100%;
}

.h-95 {
  height: 95%;
}

.h-90 {
  height: 90%;
}

.h-50 {
  height: 50%;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after,
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inherit;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container:hover {
  background: transparent;
}
.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: #2170b8;
}
.d-inline-50 {
  display: inline-block;
  width: 50%;
}
.d-inline {
  display: inline-block;
}

textarea {
  resize: none;
}
.ant-breadcrumb {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}
.ant-breadcrumb-link a {
  color: #2170b8;
  font-weight: 700;
}
.ant-breadcrumb-link a:hover {
  color: #2170b8;
}
.ant-breadcrumb-link {
  color: #231f20;
  font-weight: 400;
}
.apexcharts-tooltip {
  font-family: 'Roboto Condensed', sans-serif;
}
.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: 'Roboto Condensed', sans-serif !important;
}

.success-banner-text {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  color: #2170b8;
  text-align: center;
}
.ca-toast {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  line-height: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
}
.info-toast {
  border: 1px solid #8b8d8f;
  background-color: #d4d5d8;
  color: #8b8d8f;
}
.alert-toast {
  border: 1px solid #ec7a07;
  background-color: #f1cfae;
  color: #ec7a07;
}
.success-toast {
  border: 1px solid #36b44c;
  background-color: #d3e9da;
  color: #36b44c;
}
.warning-toast {
  border: 1px solid #ef5869;
  background-color: #f3dadf;
  color: #ef5869;
}
.ml-30 {
  margin-left: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.align-right {
  text-align: right;
}
/* table filter css new */
.ant-table-filter-column {
  display: inline-flex;
  margin: inherit;
}
.ant-table-filter-column-title {
  padding: inherit;
  flex: inherit;
}
.ant-table-filter-trigger-container {
  margin-left: 8px;
  /* text-align: center; */
  position: inherit;

  display: inherit;
  -ms-flex: inherit;
  flex: inherit;
  -ms-flex-align: inherit;
  align-items: flex-end;
  -ms-flex-item-align: flex-end;
  /* align-self: inherit; */
  /* cursor: pointer; */
}
.ant-table-filter-trigger {
  width: 15px;
}
.ant-table-filter-trigger .anticon {
  position: inherit;
  top: inherit;
  left: 50%;
  -webkit-transform: inherit;
  transform: inherit;
}
/* filter table css new end */
.setting-text {
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
}
.ant-layout-sider-collapsed .menu-setting .setting-text {
  display: none;
}

.menu-setting {
  display: inline-block;
  width: 100%;
  bottom: 100px;
  text-align: inherit;
  cursor: pointer;
}
.menu-setting img {
  margin-right: 12px;
}
.ant-layout-sider-collapsed .menu-setting img {
  margin-right: inherit;
}
.ant-layout-sider-collapsed .menu-setting {
  text-align: center;
  padding: 0 !important;
}
.setting-menu-item > span {
  display: inline-block;
  width: 100%;
}

.setting-popover-content a:active,
.setting-popover-content a:focus {
  color: #2170b8;
  font-weight: 700;
}
.setting-popover-content a {
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}
.setting-popover-content a:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.ant-popover-arrow {
  left: 16px !important;
}
.ant-popover-placement-rightBottom .ant-popover-inner {
  min-width: 150px;
}
.ant-popover-placement-rightBottom {
  position: fixed;
  padding-left: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagetitle {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  margin-bottom: 15px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #dbdbdb;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.view-cta button {
  padding: 10px 24px;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  color: #fff;
  margin-top: 5px;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center-impt {
  text-align: center !important;
}
.text-left-impt {
  text-align: left !important;
}
.text-right-impt {
  text-align: right !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mv-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mv-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mv-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.mv-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.btn-primary {
  color: #fff;
  background: #2170b8;
  border-color: #2170b8;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: capitalize;
  border-radius: 4px;
  height: 100%;
}

.btn-secondary {
  color: #2170b8;
  border-color: #2170b8;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 4px;
  height: 100%;
}

.btn-secondary-danger {
  color: #ef5869;
  border-color: #ef5869;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 4px;
  height: 100%;
}

.btn-primary span,
.btn-secondary span,
.btn-secondary-danger span {
  padding: 10px;
}

.btn-primary span.ant-btn-loading-icon,
.btn-secondary span.ant-btn-loading-icon,
.btn-secondary-danger span.ant-btn-loading-icon {
  padding: 0px;
}

.btn-sm {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: capitalize;
  border-radius: 6px;
  padding: 0 10px;
}

.rounded-corners-image {
  border-radius: 25%;
}

/* Tabs */
.ant-tabs-tab {
  font-size: 20px;
  line-height: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700 !important;
  color: #d7d6d6;
  margin-bottom: 15px !important;
  text-transform: capitalize;
  padding: 5px 0px !important;
}

.ant-tabs-tab:hover {
  color: #231f20;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #231f20;
  font-weight: 700 !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  background: #2170b8;
  /* margin-bottom: -3px; */
  height: 6px;
  border-radius: 20px;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px !important;
}

/* .ant-table-filter-column-title {
  flex: auto;
  padding: 15px 2em 15px 16px;
}

.ant-table-thead .ant-table-cell {
  padding: 20px 4px;
}

.ant-table-column-sorters,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px 4px 15px 4px;
} */

/* Fonts */
.font-18 {
  font-size: large;
}

/* Drawers */
.sec-heading {
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.sec-heading-subtitle {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  margin-bottom: 0;
}
.right-side-drawer {
  border-bottom: 1px solid #e3e3e3;
}

/* Steps */

.steps-action {
  text-align: center;
}
.carva-step-form {
  padding-left: 10px;
  padding-right: 10px;
}
/* for ideal step */
.carva-step-form .ant-steps-item-container {
  background-color: #dcdcdc;
  display: inline-block;
  border-radius: 8px;
  padding: 5px 15px;
}
.carva-step-form .ant-steps-item-title::after {
  height: 2px;
  background: #d3d3d3 !important;
  left: 112%;
}
.carva-step-form
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding-left: 0;
}
.carva-step-form .ant-steps-item-title {
  font-family: 'Roboto Condensed', sans-serif;
  padding-right: inherit;
}
.carva-step-form
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #615d5e;
}
.carva-step-form
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: #615d5e;
  font-size: 16px;
}
.carva-step-form .steps-content {
  padding: 30px 0 15px 0;
}
/* for ideal step end */
/* for progress step */
.carva-step-form .ant-steps-item-process .ant-steps-item-container {
  background-color: #0ebab3;
}
.carva-step-form
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}
.carva-step-form
  .ant-steps-item-process.ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: #fff;
}
/* for progress step end */
/* for finish step */
.carva-step-form .ant-steps-item-finish .ant-steps-item-container {
  background-color: #0ebab3;
  background-image: url('src/assets/images/check-white.png');
  background-repeat: no-repeat;
  background-position: 15px 13px;
  background-size: 18px;
}
.carva-step-form
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
  padding-left: 18px;
}
.carva-step-form
  .ant-steps-item-finish.ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  display: none;
}
.carva-step-form .ant-steps-item-finish .ant-steps-item-title::after {
  background: #0ebab3 !important;
}
.menus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80%;
}
/* finish step end */

.divider-wrapper:before,
.divider-wrapper:after {
  content: ' ';
  width: 40%;
  height: 1px;
  margin: 0 10px;
  vertical-align: super;
  background-color: #e3e3e3;
  display: inline-block;
}

/* Input Fields */
.custom-input-field {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.custom-input-field input:disabled {
  background-color: #d4d4d4 !important;
}

/* Common Components */
.page-heading {
  font-size: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
  text-transform: capitalize;
}

.section-heading {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
  text-transform: capitalize;
}

.title-heading {
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
  text-transform: capitalize;
}
.small-heading {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
  text-transform: capitalize;
}
.title-small-heading {
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
  text-transform: capitalize;
}
.text-normal {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
}
.text-small {
  font-size: 12px;
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
}
.primary-color {
  color: #2170b8;
}
.primary-button {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
}
.primary-outlined-button {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: transparent;
  border-color: #2170b8;
  border-radius: 4px;
  color: #2170b8;
  text-transform: capitalize;
}
.primary-outline-full {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 40px;
  padding: 0 30px;
  background: transparent;
  border-color: #2170b8;
  border-radius: 4px;
  color: #2170b8;
  margin-bottom: 15px;
  border: 2px solid;
}
.primary-full {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 40px;
  padding: 0 30px;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  color: #fff;
}

.cancel-outlined-full {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 40px;
  padding: 0 30px;
  background: transparent;
  border-color: #ef5869 !important;
  border: 2px solid;
  border-radius: 4px;
  color: #ef5869;
}
.cancel-full {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 40px;
  padding: 0 30px;
  background: #ef5869;
  border-color: #ef5869 !important;
  border-radius: 4px;
  color: #fff;
}
.cancel-button {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #ef5869;
  border-color: #ef5869;
  border-radius: 4px;
  color: #fff;
}
.cancel-outlined-button {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: transparent;
  border-color: #ef5869 !important;
  border-radius: 4px;
  color: #ef5869 !important;
}
.cancel-button:hover,
.cancel-full:hover,
.cancel-outlined-full:hover,
.cancel-outlined-button:hover,
.btn-secondary-danger:hover {
  background: #ea1f37 !important;
  border-color: #ea1f37 !important;
  border-radius: 4px;
  color: #fff;
}
.success-button {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #0ebab3 !important;
  border-color: #0ebab3 !important;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
}
.success-button:hover {
  background: #0a8581 !important;
  border-color: #0a8581 !important;
  color: #fff;
}
.btn-cutom-icon {
  width: 15px;
  margin-right: 5px;
}
.ca-dropdown .ant-select-selector {
  background-color: #f4f5f8 !important;
  border-radius: 4px !important;
}
.ca-dropdown .ant-select-selector .ant-select-selection-item {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.ca-dropdown .ant-select-arrow {
  color: #231f20;
}
.ca-input {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #f4f5f8;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.ca-checkbox {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
}
.ca-checkbox .ant-checkbox-inner {
  background-color: #d8e3ec;
  border-radius: 4px;
}
.ca-textarea {
  background-color: #f4f5f8 !important;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  font-family: 'Roboto Condensed', sans-serif;
}
.ca-textarea::placeholder {
  font-family: 'Roboto Condensed', sans-serif;
}

.ant-btn {
  border-radius: 4px;
}

.ant-btn.ant-btn-primary:hover,
.ant-btn.ant-btn-primary:focus {
  background: #28a0f2 !important;
  border-color: #28a0f2 !important;
  color: #fff;
}

.ant-btn:not(.ant-btn-link):hover,
.ant-btn:not(.ant-btn-link):focus,
.ant-btn:not(.ant-btn-link):active {
  background: #28a0f2;
  border-color: #28a0f2;
  color: #fff !important;
}

.head-box-va:hover {
  color: #28a0f2;
}

.head-box-download:hover {
  color: #28a0f2;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

.rscb-img .specification-img {
  height: 80px;
  width: 80px;
  border-radius: 5px;
}

.auction-lost-text {
  color: #e10f0f;
  font-weight: 500;
}

.auction-won-text,
.proforma-approved-text,
.paid-text {
  color: #0ebab3;
  font-weight: 500;
}

.carava-source-text {
  color: #2170b8;
  font-weight: 500;
}

.nav-source-text {
  color: #f86624;
  font-weight: 500;
}

.auction-pending-proforma-approval-text,
.proforma-pending-approval-text,
.payment-pending-text {
  color: #f86624;
  font-weight: 500;
}

.plain-divide {
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 15px;
}
.sub-heading {
  font-size: 18px;
  margin-bottom: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  display: inline-block;
}
.edit-cta {
  float: right;
}

.list-sub-items-detail {
  display: inline-block;
  width: 33.33%;
}

.circle-marker-legend {
  height: 8px;
  width: 8px;
  left: 0px;
  top: 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 6px;
  background: #2170b8;
  margin-right: 5px;
  display: inline-block;
}

.square-marker-legend {
  height: 8px;
  width: 8px;
  left: 0px;
  top: 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 0px;
  background: #2170b8;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.win-score-80-100 {
  color: #36b44c;
}

.win-score-50-80 {
  color: #ec7a07;
}

.win-score-0-50 {
  color: #ea1f37;
}

.upload-files-divider {
  background-color: #d9d9d9;
  color: #d9d9d9;
}

.uploaded-document-title {
  margin-right: 5px !important;
}

.uploaded-document-action {
  padding-left: 5px !important;
  padding-right: 0px !important;
}

.empty-plant-info-text {
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  color: #231f20;
  margin-bottom: 18px;
  display: inline-block;
}
