.ai-userinfo-img {
  width: 60px;
  height: 60px;
  float: left;
}
.ai-userinfo-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ai-userinfo-img-upload {
  width: calc(100% - 60px);
  float: left;
  padding: 0 15px;
}
.accept-invite-img-upload {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
}
.accept-invite-img-upload .ant-form-item {
  margin: 0;
}
.ai-userinfo-img-upload .ant-form-item-label {
  padding-bottom: 0;
}
.ai-userinfo-img-upload .ant-form-item-label label {
  vertical-align: middle;
}
.ai-userinfo-img-upload .ant-upload-select-text {
  margin-top: 0;
}
