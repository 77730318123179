.acceptInviteCompany .specification-tag-input .ant-select-selector {
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  background: transparent;
  padding: 4px 0px;
  border: 0;
  border-bottom: 1px solid #918f8f;
}
.acceptInviteCompany
  .specification-tag-input
  .ant-select-selector
  .ant-select-selection-search-input {
  font-family: 'Roboto Condensed', sans-serif;
}
.acceptInviteCompany .ant-select-selection-placeholder {
  font-size: 20px !important;
}
.acceptInviteCompany
  .specification-tag-input
  .ant-select-selector
  .ant-select-selection-item {
  background-color: #2170b8;
  color: #fff;
  border-radius: 4px;
  font-size: 10px;
}
.acceptInviteCompany
  .specification-tag-input
  .ant-select-selector
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  color: #fff;
}
