.current-plant-bid td {
  background: #231f20 !important;
  color: #fff !important;
}

.current-plant-bid-sub {
  width: 40px;
  margin-left: 10px;
  display: inline-block;
  text-transform: uppercase;
  color: #231f20;
  background: #fff;
  font-size: 10px;
  /* padding: 5px 16px; */
  border-radius: 15px;
  line-height: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  line-height: 20px;
}
