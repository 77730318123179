.acceptinvite-container {
  padding: 50px 20%;
}
.acceptinvite-container .ant-steps {
  margin-bottom: 30px;
}
.acceptinvitesection {
  overflow-y: scroll;
}
/* .acceptinvite-container .steps-action {
  text-align: left;
} */
.acceptinvitesection.carva-step-form .ant-steps-item-title::after {
  left: 119%;
}
