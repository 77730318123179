.reminder-list-card {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 15px;
  padding-top: 15px;
}
.rmdr-img-bg {
  float: left;
  background: #0ebab3;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 8px;
  display: inline-block;
}
.rmdr-img-bg img {
  width: 20px;
}
.reminder-list-desc {
  padding: 0 10px;
  display: inline-block;
  width: calc(100% - 160px);
  float: left;
}
.reminder-list-desc .desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 5px;
}
.reminder-list-desc .date {
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 5px;
}
.reminder-list-card .view-cta {
  display: inline-block;
  float: left;
  text-align: center;
  width: 118px;
}
.reminder-o-list {
  height: 220px;
  overflow-y: scroll;
}

.reminder-o-list .reminder-list-card:last-child {
  border-bottom: 0;
}
