.addtrackcomments textarea {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}
.mt {
  margin-top: 20px;
}

.commentstyle {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #231f20;
}
.comments {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.comment-sec {
  padding-left: 0;
  list-style: none;
}
.comment-box {
  position: relative;
  padding-bottom: 25px;
}
.comment-user-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}
.comment-user-img img {
  width: 100%;
  height: 100%;
  display: block;
}
.comment-vline {
  border-left: 1px solid #918f8f;
  position: absolute;
  bottom: 8px;
  left: 9px;
  height: calc(100% - 36px);
}
.add-comment-vline {
  border-left: 1px solid #918f8f;
  position: absolute;
  bottom: 0px;
  left: 9px;
  height: calc(100% - 30px);
}
.comnt-details {
  padding-left: 30px;
}
.cmnt-username {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  display: inline-block;
  margin-bottom: 10px;
}
.cmnt-dot {
  margin-right: 12px;
  margin-left: 12px;
  font-size: 24px;
  line-height: 14px;
  display: inline-block;
  vertical-align: sub;
  color: #918f8f;
}
.cmnt-date {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
}
.cmnt-src {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
  vertical-align: sub;
  float: right;
  margin-bottom: 10px;
}
.cmnt-src img {
  width: 18px;
  margin-right: 8px;
}
.user-cmnt {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0;
}
.comment-sec .comment-box:last-child .comment-vline {
  display: none;
}
.comment-sec .comment-box:last-child {
  padding-bottom: 0;
}
.edited-cmnt-box {
  background-color: #f4f5f8;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 10px;
}
.edited-cmnt-box .ant-form-item {
  margin-bottom: 0;
}
.edited-cmnt::placeholder {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.edited-cmnt {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  border: none;
  background-color: transparent;
  padding: 0;
}
.edited-cmnt:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cmnt-attacment .ant-upload-select-text {
  margin-top: 10px;
  margin-bottom: 0px;
}
.attch-icon {
  vertical-align: middle;
  margin-right: 8px;
}
