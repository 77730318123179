.sellbd-history-dat .header-elements {
  display: inline-block;
  float: right;
}
.sellbd-history-dat .header-elements .select-drop {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  display: inline-block;
  margin-right: 15px;
}
.sellbd-history-dat .header-elements .ant-select {
  margin-left: 15px;
}
.sellbd-history-dat .chart-area {
  display: inline-block;
  width: 100%;
}
.sellbd-history-dat {
  display: inline-block;
  width: 100%;
}
.sell-history-data {
  display: flex;
}

.bid-history-table .ant-table-tbody tr td:nth-child(4) {
  color: #36b44c;
}
