.nav-profile-pop {
  box-shadow: 0px 8px 20px #0000001a;
  background: #fff;
  padding: 30px 25px;
  text-align: center;
  border-radius: 4px;
}
.profile-pop-img {
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.profile-pop-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.user-name-pop {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #2170b8;
  text-align: center;
  line-height: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.user-designation-pop {
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: #231f20;
  text-align: center;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.user-mail-pop {
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: #231f20;
  text-align: center;
  margin-bottom: 10px;
}
.prof-underline {
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background-color: #231f20;
  margin-bottom: 10px;
  margin-top: 10px;
}
.propop-company-name {
  background-color: #231f20;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  border-radius: 4px;
  padding: 10px 15px;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 8px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.propop-company-name:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
.prof-logout-btn {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: transparent;
  border: 2px solid #2170b8;
  border-radius: 4px;
  color: #2170b8;
  font-weight: 700;
}
