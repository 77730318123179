.currentvrs {
  color: #2170b8;
  font-weight: 800;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #2170b8;
  opacity: 1;
}

.person_name,
.person_name > a {
  color: #0ebab3;
  font-weight: 800;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0px;
}

.headingdate {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
}

.pdflink {
  font-size: 14px;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  vertical-align: middle;
}

.version-info {
  width: 80%;
  display: inline-block;
  vertical-align: middle;
}
.current-version {
  width: 20%;
  display: inline-block;
  text-align: right;
}
.current-version .version-num {
  margin-bottom: 0;
}

.agreement-reminder-list-card {
  width: 100%;
  float: left;
  padding-bottom: 12px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 15px;
}

.agreement-reminder-list-card .view-cta {
  display: inline-block;
  float: left;
  text-align: center;
  width: 200px;
  font-family: 'Roboto Condensed', sans-serif;
}

.agreement-reminder-list-desc {
  width: 50%;
  float: left;
  font-family: 'Roboto Condensed', sans-serif;
}

.agreement-reminder-list-desc p {
  margin-bottom: 0px;
}

.v-history-btns {
  float: left;
  width: 50%;
  text-align: right;
  padding-top: 5px;
}
.v-history-btns button {
  vertical-align: middle;
}
.v-history-btns .version-num {
  vertical-align: middle;
}
