.empty-section {
  height: 240px;
  position: relative;
}
.empty-section-small {
  height: 100px;
  position: relative;
}
.no-data-elements {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.nd-img {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-bottom: 13px;
}
.nd-img img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.nd-text {
  font-size: 16px;
  color: #bebebe;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
