.po-track-del-status {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.po-track-del-status .left-item,
.po-track-del-status .right-item {
  width: 38%;
  display: inline-block;
}
.delivery-status-title {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.delivery-status {
  color: #0ebab3;
  font-size: 18px;
}
.timeline-box {
  display: inline-block;
  position: relative;
  height: 57px;
  width: 16px;
}
.timeline-box::before {
  top: 0;
  position: absolute;
  display: block;
  width: 2px;
  height: 20px;
  background: #f0f0f0;
  content: '';
  left: 50%;
  transform: translate(-50%, 0);
}
.timeline-box::after {
  top: 0;
  position: absolute;
  display: block;
  width: 2px;
  height: 20px;
  background: #f0f0f0;
  content: '';
  left: 50%;
  transform: translate(-50%, 0);
}
.timeline-check {
  position: absolute;
  top: 18px;
}
.left-content .date-time:last-child {
  margin-bottom: 0;
}
.ca-delivery-timeline {
  list-style: none;
  padding-left: 0;
}
.ca-delivery-timeline li {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
.ca-delivery-timeline .left-content {
  width: 120px;
  position: absolute;
  text-align: right;
  padding-right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
/* .ca-delivery-timeline li:first-child{padding-top: 0;} */

.ca-delivery-timeline .tim-ch {
  left: 120px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.ca-delivery-timeline .timeline-sections .center-content-upline,
.ca-delivery-timeline .timeline-sections .center-content-downline {
  position: absolute;
  left: 126px;
  border-left: 3px solid #dedede;
  height: 50%;
}
.ca-delivery-timeline .timeline-sections .center-content-upline {
  top: 0;
}
.ca-delivery-timeline .timeline-sections .center-content-downline {
  bottom: 0;
}
.ca-delivery-timeline .timeline-sections.active .center-content-upline,
.ca-delivery-timeline .timeline-sections.active .center-content-downline {
  border-left: 3px solid #0ebab3;
}
.ca-delivery-timeline .timeline-sections:first-child .center-content-upline {
  display: none;
}

.ca-delivery-timeline .timeline-sections:last-child .center-content-downline {
  display: none;
}
.ca-delivery-timeline .right-content {
  position: relative;
  word-break: break-word;
  left: 136px;
  padding-left: 20px;
  text-align: left;
}
.tracking-desc {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0;
}
.tracking-desc {
  margin-bottom: 10px;
}
.tracking-desc:last-child {
  margin-bottom: 0px;
}

.ca-delivery-timeline .date-time {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.ca-delivery-timeline .date-time:first-child {
  margin-bottom: 10px;
}

.ca-delivery-timeline .tim-ch .ant-checkbox-inner::after {
  top: 46%;
  left: 25%;
  display: table;
  width: 4.714286px;
  height: 8.142857px;
}

.ca-delivery-timeline .ant-checkbox-inner {
  background-color: #dedede;
  border: none;
}
.ca-delivery-timeline .ant-checkbox-checked::after {
  border: none;
}
.ca-delivery-timeline .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0ebab3 !important;
  border-color: #0ebab3;
}

.multiactivity .tim-multi-chk {
  margin-right: 10px;
}
.ca-delivery-timeline .tim-multi-chk .ant-checkbox-inner::after {
  top: 46%;
  left: 25%;
  display: table;
  width: 4.714286px;
  height: 8.142857px;
}
.tim-multi-chk::after {
  position: absolute;
  top: 50%;
  left: -20px;
  display: block;
  width: 20px;
  height: 3px;
  background: #dedede;
  content: '';
}
.multiactivity .right-content {
  border-left: 3px solid #dedede;
  margin-left: 30px;
}
.multiactivity .right-content .tracking-desc {
  position: relative;
}
.multiactivity.active .tim-multi-chk::after {
  background: #0ebab3;
}
.multiactivity .right-content {
  position: relative;
}
.multiactivity .right-content .multiactivity-divider {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 3px;
  background: #d3d3d3;
  left: -33px;
}
.multiactivity.active .right-content {
  border-left: 3px solid #0ebab3;
}
.multiactivity.active .right-content .multiactivity-divider {
  background: #0ebab3;
}
