.incoterm-cat {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  margin-bottom: 10px;
}
.auc-detail-inco .incoterms {
  margin-bottom: 15px;
  margin-top: 0;
}
.auc-detail-inco .incoterms .ant-checkbox {
  display: none;
}
