.heading-with-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.view-details-btn {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  color: #fff;
}
