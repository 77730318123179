.deliver-star .ant-space-item {
  margin-bottom: 0 !important;
}
.deliver-star .ant-rate {
  font-size: 16px;
  line-height: 16px;
  color: #ffcc33;
}
.deliver-star .ant-rate-star:not(:last-child) {
  margin-right: 5px;
}
.deliver-star .deliver-star-text {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0;
}
.text1 {
  color: #231f20;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
}
.text1-desc,
.modal-rate-text {
  font-family: 'Roboto Condensed', sans-serif;
}
.modal-rate .ant-rate {
  font-size: 16px;
  line-height: 16px;
  color: #ffcc33;
  margin-right: 15px;
}
.plordfull-search {
  margin-right: 15px;
}
.plordfull-search .ant-input-group .ant-input-group-addon button {
  background-image: url('../../../../../assets/images/search.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 10px;
  background-color: #2170b8;
  border-color: #2170b8;
  padding: 4px 15px 4px 30px;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}
.plordfull-search .ant-input-group .ant-input-group-addon {
  background-color: #2170b8;
  border-radius: 0px 4px 4px 0;
}
.plordfull-search .ant-input {
  border: 1px solid #707070;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  line-height: 22px;
  border-radius: 4px;
}
.plordfull-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 4px 4px 0;
}
