.team-selection .ant-select-selector {
  border: 0 !important;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.team-selection .ant-select-selector .ant-select-selection-item {
  color: #231f20;
}
.team-selection {
  float: right;
}
.team-selection .anticon-caret-down {
  color: #231f20;
}
