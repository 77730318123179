.awarded-bid-table .ant-table-tbody tr td:nth-child(5) {
  color: #0ebab3 !important;
}
.awarded-bid-table tr td:last-child {
  text-align: center;
}
.negotiate-cta {
  border-color: #0ebab3;
  background-color: #0ebab3;
  color: #fff;
}
.negotiate-cta img {
  width: 15px;
  margin-right: 5px;
}
.negotiate-cta:hover {
  border-color: #0a8581 !important;
  background-color: #0a8581 !important;
  color: #fff;
}
.negative-savings {
  color: #e10f0f;
}
.positive-savings {
  color: #0ebab3;
}
