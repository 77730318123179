.field-value {
  margin-left: 1px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: 'Roboto Condensed', sans-serif;
}

.view-details-cta {
  vertical-align: top;
  background-color: transparent;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #2170b8;
  border-radius: 4px;
}
