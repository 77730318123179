.mybid li {
  display: flex !important;
  align-items: center !important;
}

.mybid .left-item {
  width: 15% !important;
}

.mybid .right-item {
  width: auto !important;
  display: inline-block;
}

.rightheading {
  float: left;
  width: 28%;
  text-align: right;
}
.leftheading {
  float: left;
  width: 72%;
}
.auction-end-det {
  margin-bottom: 0;
}
.place-bid-cta {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  height: 49px;
  padding: 0 30px;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 15px;
}
.mybid .leftheading .rscb-img {
  width: 80px;
  height: 80px;
  display: inline-block;
}
.mybid .leftheading .rscb-desc {
  padding: 17px 22px;
  width: calc(100% - 80px);
  display: inline-block;
  vertical-align: middle;
}
.mybid .leftheading .rscb-desc-company {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #231f20;
  margin-bottom: 0;
}

.incoterm-checkbox {
  display: inline-block;
  width: 145px;
  vertical-align: top;
  text-align: left;
}

.fullwidth {
  width: 100%;
}
.decimalval {
  width: 130px;
  background: #f4f5f8 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  opacity: 1;
}
.infomsg {
  color: #0ebab3;
}
.ogbid-detail .ogbid-detail-items-list {
  padding-left: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 0;
  display: flex;
}
.ogbid-detail .ogbid-detail-items-list .ogbid-detail-items {
  display: inline-block !important;
  font-size: 40px;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  width: 25%;
  text-align: center;
  border-right: solid 1px #707070;
}
.ogbid-detail .ogbid-detail-items-list .ogbid-detail-items:last-child {
  border-right: none;
}
.ogbid-detail .ogbid-detail-items-list .ogbid-detail-items span {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  vertical-align: middle;
  margin-left: 15px;
  font-weight: 300;
}
.ogbid-form .rscb-items li {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 10px;
}
.ogbid-form .rscb-items li .left-item {
  color: #918f8f;
}
.ogbid-form .left-item {
  width: 20%;
  display: inline-block;
}
.ogbid-form .rscb-items li .right-item {
  width: 80%;
  display: inline-block;
  vertical-align: top;
}
.ogbid-form .rscb-items li .right-item .avl-fcs {
  width: 300px;
}
.ogbid-form-check {
  display: inline-block;
  width: 145px;
}
.ogbiddetail .leftheading .rscb-img {
  width: 80px;
  height: 80px;
  display: inline-block;
}
.ogbiddetail .leftheading .rscb-desc {
  padding: 17px 22px;
  width: calc(100% - 80px);
  display: inline-block;
  vertical-align: middle;
}
.ogbiddetail .leftheading .rscb-desc-company {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #231f20;
  margin-bottom: 0;
}
