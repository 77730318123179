.auction-info-card {
  background-color: #231f20;
  border-radius: 12px;
  padding: 20px 30px;
}
.auction-info-items-list {
  padding-left: 0;
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.auction-info-items-list .auction-info-items {
  display: inline-block;
  font-size: 35px;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  padding-right: 40px;
  border-right: solid 1px #707070;
  text-align: center;
  /* width: 20% !important; */
}
.auction-info-items-list .auction-info-items::after {
  padding-right: 30px;
  border-right: solid 1px #707070;
}
.auction-info-items-list .auction-info-items:first-child {
  text-align: left;
}
.auction-info-items-list .auction-info-items:last-child {
  text-align: right;
  vertical-align: top;
  line-height: 16px;
  padding-top: 10px;
  border-right: 0;
  padding-right: 0;
}
.auction-info-items-list .auction-info-items:last-child .date-time {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}
.auction-info-items-list .auction-info-items span {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: 15px;
}
.auction-info-items-list .auction-info-items:last-child .time-left {
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.auction-info-card-inside {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 30px;
  margin-top: 20px;
}
.auction-info-card-inside-detail {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  margin-bottom: 0;
}
.auction-info-card-cell {
  width: 33.33%;
  text-align: center;
  display: inline-block;
}
.auction-info-card-cell:nth-child(2) {
  border-right: 1px solid #231f20;
  border-left: 1px solid #231f20;
}

.helper-text {
  font-size: 15px !important;
  color: gray !important;
}
