.enter-auction-button {
  height: 49px;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  padding: 0 30px;
  font-size: 16px !important;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  margin-bottom: 15px;
}

.not-interested-button {
  height: 49px;
  border-radius: 4px;
  padding: 0 30px;
  font-size: 16px !important;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  margin-bottom: 15px;
}
