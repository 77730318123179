.carava-at-textarea {
  background: #f4f5f8;
  width: 500px;
  border-radius: 4px;
  margin-top: 5px;
}
.at-right-text {
  text-align: right;
}

/* .rscb-details .left-item
{
    text-align: left;
}
.rscb-details .rscb-items li .right-item
{
    text-align: left;
}

.carousel .control-dots
{
    top: 0 !important;
    position: relative  !important;
}
.input-field-argee
{


background-color: #F4F5F8 !important;

    width: 80%;
    border-radius: 4px;
    border: 1px solid #E3E3E3;
}
.currentvrs
{
    color: #2170B8;
    font-weight: 800;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #2170B8;
    opacity: 1;
}
.person_name
{
    color:#0EBAB3;
    font-weight: 800;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    margin-bottom: 0px;

}
.headingdate
{
    color: #918F8F;
    font-family: 'Roboto Condensed', sans-serif;
}
.agreement-reminder-list-desc {
    padding: 0 10px;
    display: inline-block;
    width: calc(100% - 200px);
    float: left;
    font-family: 'Roboto Condensed', sans-serif;
}
.agreement-reminder-list-card .view-cta {
    display: inline-block;
    float: left;
    text-align: center;
    width: 200px;
    font-family: 'Roboto Condensed', sans-serif;
}
.pdflink
{
    font-size: 14px;
    color: #2170B8;
}
.agreement-reminder-list-desc p{
    margin-bottom: 0px;
}
.color-blue-link
{
    color: #2170B8;
    border-bottom: 1px solid;
    margin-left: 9px;
    font-weight: bold;
}
.agreementtabstyle .ant-tabs-nav-list
{
    transform: translate(0px, 0px);
    margin: auto;
    padding: 10px;
}
.agreementtabstyle .ant-tabs-tab.ant-tabs-tab-active
{
    color: #FFF;
    font-size: 14px;
    background: #2170B8;
    border: 1px solid #2170B8;
    border-radius: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 25px;

}
.agreementtabstyle .ant-tabs-tab{
    padding: 3px 20px 3px 20px;
    font-size: 14px;
    margin: 0;
}
.agreementtabstyle .ant-tabs-tab.ant-tabs-tab-active span{
    color: #FFF;
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;


}
.agreementtabstyle .ant-tabs-tab span{
    color: #231F20;
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;


}
.agreementtabstyle .ant-tabs-nav-list
{
    transform: translate(0px, 0px);
    padding: 0px 0px 0px 0px;
    font-size: 14px;
    background: #F4F5F8;
    border-radius: 30px;
    border: 1px solid #F4F5F8;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 25px;
}

.agreementtabstyle .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before
{
    border-bottom:none !important;
}
.agreementtabstyle .notification-alerts .ant-tabs-ink-bar
{
    background: none;
}


.agreementtabstyle .list-sub-items.setwidth .color-blue input ,  textarea {
    color: #2170B8;
    margin-top: 10px;
}

.agreementtabstyle textarea
{
    background-color: #F4F5F8 !important;
    width: 60%;
    border-radius: 4px;
    border: 1px solid #E3E3E3;
} */
.rscb-details .left-item {
  text-align: left;
}
.rscb-details .rscb-items li .right-item {
  text-align: left;
}

.carousel .control-dots {
  top: 0 !important;
  position: relative !important;
}
.input-field-argee {
  background-color: #f4f5f8 !important;
  /* color: #231F20; */
  width: 80%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.color-blue-link {
  color: #2170b8;
  border-bottom: 1px solid;
  margin-left: 9px;
  font-weight: bold;
}
.auction-link {
  color: #2170b8;
  border-bottom: 1px solid;
  font-weight: bold;
}
.agreementtabstyle .ant-tabs-tab.ant-tabs-tab-active {
  color: #fff;
  font-size: 14px;
  background: #2170b8;
  border: 1px solid #2170b8;
  border-radius: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 25px;
}
.agreementtabstyle .ant-tabs-tab {
  padding: 3px 20px 3px 20px !important;
  margin: 0 !important;
}
.agreementtabstyle .ant-tabs-tab.ant-tabs-tab-active span {
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
}
.agreementtabstyle .ant-tabs-tab span {
  color: #231f20;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-align: center;
}
.agreementtabstyle .ant-tabs-nav-list {
  padding: 0px 0px 0px 0px;
  font-size: 14px;
  background: #f4f5f8;
  margin: auto;
  border-radius: 30px;
  border: 1px solid #f4f5f8;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 25px;
}

.agreementtabstyle .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.agreementtabstyle .notification-alerts .ant-tabs-ink-bar {
  background: none;
}

.agreementtabstyle .list-sub-items.setwidth .color-blue input,
textarea {
  color: #2170b8;
  margin-top: 10px;
}

.agreementtabstyle textarea {
  background-color: #f4f5f8 !important;
  width: 60%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.agreementtabstyle .ant-tabs-ink-bar {
  background: none !important;
}
