.login-right {
  background: #2264a0 url('../../../assets/images/seafood/shrimp-bg.jpg')
    no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 180px 100px;
  box-shadow: inset 0 0 0 50vw rgb(34 100 160 / 85%);
}
.login-left,
.login-right {
  position: relative;
}
.login-right-details {
  width: 100%;
  margin-top: 90px;
}
.login-left-details {
  width: 60%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.color-green {
  color: #36b44c !important;
}
.login-left-details .form-btn {
  background: #2170b8;
  border-color: #2170b8;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  height: 40px;
  border-radius: 4px;
  padding: 0 25px;
  margin-top: 24px;
}
.caro-div p {
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  margin-top: 30px;
}
.caro-div h1 {
  margin-bottom: 30px;
  color: #fff;
}
.caro-div {
  height: 330px;
  padding-top: 10px;
}
.login-right .ant-carousel .slick-dots {
  right: inherit;
  margin-left: inherit;
}
.login-right .ant-carousel .slick-dots li {
  height: 10px;
  width: 10px;
}
.login-right .ant-carousel .slick-dots button {
  height: 10px;
  border-radius: 20px;
}
.log-logo {
  width: 250px;
  margin-bottom: 30px;
}
.log-sign-form-desc {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #272727;
}
.log-sign-form-desc a {
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.all-form label {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #918f8f;
}
.all-form .ant-checkbox-wrapper {
  font-size: 16px;
  color: #272727;
}
.all-form label::before {
  display: none !important;
}
.all-form input {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  background: transparent;
  padding: 4px 0px;
  border: 0;
  border-bottom: 1px solid #918f8f;
}
.all-form input:focus {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
.all-form .ant-input-password {
  background: transparent;
  padding: 4px 15px 4px 0;
  border: 0;
  border-bottom: 1px solid #918f8f;
}
.all-form .ant-input-affix-wrapper:focus,
.all-form .ant-input-affix-wrapper-focused {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
.all-form-links {
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #2170b8;
}
.all-form-links:hover {
  color: #2170b8;
}
.all-form .submit {
  background: #2170b8;
  border-color: #2170b8;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  height: 40px;
  border-radius: 4px;
  padding: 0 25px;
}
.all-form .ant-input-password .ant-input-suffix {
  font-size: 24px;
}
.all-form .ant-input-password .ant-input-suffix svg {
  color: #212121;
}
.all-form .ant-form-item-explain-error {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #ef5869;
  margin-bottom: 25px;
}
.tnc-check a {
  color: #212121;
  text-decoration: underline;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: transparent;
}

.all-form-links-secondary {
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #8f8f8f;
}
.all-form-links-secondary :hover {
  color: #2170b8;
}
