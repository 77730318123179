.pending-invites-table .ant-table-thead > tr > th,
.pending-invites-table .ant-table-tbody > tr > td,
.pending-invites-table .ant-table tfoot > tr > th,
.pending-invites-table .ant-table tfoot > tr > td {
  padding: 10px 10px 10px 0;
}

.pending-invites-cta {
  text-align: right;
}
.pendinginvitetime {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}
