.rscb-info {
  width: 100%;
}

.rscb-info .rscb-img {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.rscb-info .rscb-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rscb-info .rscb-desc {
  padding: 17px 22px;
  width: calc(100% - 80px);
  display: inline-block;
  vertical-align: middle;
}

.rscb-info .rscb-desc .rscb-desc-cin {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  margin-bottom: 10px;
  line-height: 16px;
}

.rscb-info .rscb-desc .rscb-desc-company {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: #231f20;
  margin-bottom: 0;
}

.rscb-items {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.rscb-details .left-item {
  width: 20%;
  display: inline-block;
}

.rscb-details .right-item {
  width: 80%;
  display: inline-block;
}

.rscb-details .rscb-items li {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 10px;
}

.rscb-details .rscb-items li .left-item {
  color: #918f8f;
}

.rscb-details .rscb-items li .right-item {
  color: #231f20;
}
.rscb-bid-summary .rscb-bid-summary-items {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.rscb-bid-summary .rscb-bid-summary-items li {
  font-family: 'Roboto Condensed', sans-serif;
  display: inline-block;
  margin-right: 137px;
}
.rscb-bid-summary .rscb-bid-summary-items li .bid-summary-left-item {
  color: #918f8f;
}
.rscb-bid-summary .rscb-bid-summary-items li .bid-summary-right-item {
  color: #231f20;
}
.bid-sum-title {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
}
.planned-sel-title {
  font-family: 'Roboto Condensed', sans-serif;
}
.planned-vi-sel-btn {
  border-radius: 4px;
}
.planned-vi-sel-btn .ant-select-selector {
  background-color: #f4f5f8 !important;
  border-radius: 4px !important;
}
.planned-vi-sel-btn .ant-select-arrow {
  color: #231f20;
}
.planned-vi-sel-btn .ant-select-selector .ant-select-selection-item {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.ant-select-item-option-content {
  font-family: 'Roboto Condensed', sans-serif;
}
.rscb-table .ant-table-thead {
  display: none;
}
.rscb-table .ant-table-tbody tr td:first-child {
  font-family: 'Roboto Condensed', sans-serif;
}
.rscb-table .ant-table-tbody tr td .spantext {
  color: #918f8f;
}
.rscb-table .ant-table {
  background: #f4f5f8;
  padding: 15px 10px;
  border: 1px solid #e3e3e3;
}
.rscb-table .ant-table-tbody tr:last-child td {
  font-family: 'Roboto Condensed', sans-serif;
}
.rscb-table .ant-table-tbody tr:first-child td {
  color: #918f8f;
}
.rscb-table .ant-table-tbody tr:first-child td:first-child {
  color: #231f20;
}
.rscb-table .ant-table {
  border-radius: 4px;
}
.rscb-editable .rscb-details .rscb-items .input-field {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.rscb-editable .rscb-details .rscb-items .input-field > input {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
}

.rscb-editable .rscb-details .rscb-items .input-field[disabled] {
  background-color: #d4d4d4 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.rscb-editable
  .rscb-details
  .rscb-items
  .input-field::-webkit-input-placeholder {
  /* Edge */
  color: #231f20;
}

.rscb-editable .rscb-details .rscb-items .input-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #231f20;
}

.rscb-editable .rscb-details .rscb-items .input-field::placeholder {
  color: #231f20;
}

.rscb-editable .rscb-details .rscb-items .input-field .ant-picker-suffix {
  color: #231f20cf;
}

.rscb-editable .rscb-details .rscb-items .input-field .ant-select-arrow {
  color: #231f20;
}

.rscb-editable
  .rscb-details
  .rscb-items
  .input-field
  .ant-picker-input
  input::-webkit-input-placeholder {
  /* Edge */
  color: #231f20;
}

.rscb-editable
  .rscb-details
  .rscb-items
  .input-field
  .ant-picker-input
  input:-ms-input-placeholder {
  /* Edge */
  color: #231f20;
}

.rscb-editable
  .rscb-details
  .rscb-items
  .input-field
  .ant-picker-input
  input::placeholder {
  /* Edge */
  color: #231f20;
}
.rscb-editable .rscb-details .right-item {
  width: 25%;
  display: inline-block;
}

.species-details-item {
  overflow-x: auto;
}

.species-details-item .ant-tag {
  margin-bottom: 5px;
}
