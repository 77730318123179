.bid-chart .header {
  float: right;
  display: inline-flex;
  align-items: center;
}
.bid-chart #chart {
  float: left;
  width: 100%;
  overflow: hidden !important;
}

.header .ht-header-select,
.header .toolbar {
  display: inline-block;
}
.header .ant-select {
  margin-left: 15px;
}

.header .select-drop {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  display: inline-block;
  margin-right: 15px;
}
.header .toolbar {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  display: inline-block;
  margin-right: 15px;
  font-weight: 700;
}
.header .toolbar button:first-child {
  margin-left: 15px;
}
.header .toolbar button {
  background-color: #d8e3ec;
  border: 0;
  font-size: 10px;
  padding: 4px 15px;
  border-radius: 50px;
  margin-right: 5px;
}
.header .toolbar button:focus {
  outline: 0;
}
.header .toolbar button.active {
  background-color: #2170b8;
  color: #fff;
}
