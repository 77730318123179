.total-proforma-count {
  text-align: center;
  margin-top: 30px;
}
.total-proforma-count-no {
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 65px;
  line-height: 65px;
  margin-bottom: 20px;
}
.total-proforma-count-text {
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
}
.proforma-progress-bar {
  float: left;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 15px;
}
.proforma-progress-bar-title {
  color: #fff;
  display: flex;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 5px;
}
.proforma-progress-bar-title span {
  /* width: 33.33%; */
  color: #fff !important;
  line-height: 16px;
  margin-right: auto;
  margin-left: auto;
}
/* .ant-progress-inner{position: relative;} */
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #2170b8;
  border-radius: 0;
}
.ant-progress-inner {
  background-color: #0ebab3;
}

.total-proforma-date {
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 15px;
}
.total-proforma-date p {
  margin-bottom: 0;
  font-size: 15px;
  color: #fff;
  line-height: 15px;
  font-family: 'Roboto Condensed', sans-serif;
}

.total-proforma-progress-bar {
  display: flex;
  width: 100%;
}

.approved-proforma-bar,
.pending-proforma-bar {
  border-radius: 20px;
  position: relative;
  height: 10px;
  margin-right: 5px;
}
.pending-proforma-bar {
  margin-right: inherit;
}
.approved-proforma-bar {
  background: #2170b8;
}
.pending-proforma-bar {
  background: #0ebab3;
}

.approved-proforma-bar-h,
.pending-proforma-bar-h {
  position: absolute;
  right: 0px;
  top: 2px;
  height: 20px;
  width: 4px;
  border-radius: 20px;
}
.total-proforma-progress-bar .bar-number {
  margin-bottom: 0;
  bottom: -25px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  position: absolute;
  right: -1px;
}
.approved-proforma-bar .approved-proforma-bar-h {
  background: #2170b8;
}
.pending-proforma-bar .pending-proforma-bar-h {
  background: #0ebab3;
}
