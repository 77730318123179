.historicaltrend-header .ht-header-select,
.historicaltrend-header .toolbar {
  display: inline-block;
}
.historicaltrend-header .ant-select {
  margin-left: 15px;
}

.historicaltrend-header .select-drop {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  display: inline-block;
  margin-right: 15px;
}
.historicaltrend-header .toolbar {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  display: inline-block;
  margin-right: 15px;
}
.historical-trend-chart .historicaltrend-header {
  float: right;
}
.historical-trend-chart #chart-timeline {
  float: left;
  width: 100%;
  overflow: hidden !important;
}
.historicaltrend-header .toolbar button:first-child {
  margin-left: 15px;
}
.historicaltrend-header .toolbar button {
  background-color: #d8e3ec;
  border: 0;
  font-size: 10px;
  padding: 4px 15px;
  border-radius: 50px;
  margin-right: 5px;
}
.historicaltrend-header .toolbar button:focus {
  outline: 0;
}
.historicaltrend-header .toolbar button.active {
  background-color: #2170b8;
  color: #fff;
}
.apexcharts-yaxis-texts-g text {
  font-family: 'Roboto Condensed', sans-serif !important;
}
.apexcharts-xaxis-texts-g text {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif !important;
}
.apexcharts-yaxis-annotations text {
  font-family: 'Roboto Condensed', sans-serif !important;
}
.target-price-anno {
  font-family: 'Roboto Condensed', sans-serif !important;
  color: #ed474a;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
}
.lowest-bid-anno {
  font-family: 'Roboto Condensed', sans-serif !important;
  color: #0ebab3;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  margin-left: 5px;
}
