.addmem-box {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
}
.addmember-left {
  width: 160px;
  display: inline-block;
  vertical-align: middle;
}
.addmember-left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.addmember-right {
  width: calc(100% - 160px);
  display: inline-block;
  vertical-align: middle;
  padding: 0 20px;
}
.addmember-desc {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
}
.invite-mem-btn .anticon-plus {
  font-size: 14px;
}
