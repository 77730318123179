.end-auction-cta {
  float: right;
  background-color: #ef5869;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #ef5869;
  border-radius: 4px;
}
.auction-info .rscb-desc-cin {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  margin-bottom: 10px;
  line-height: 16px;
}
.auction-info .rscb-desc-company {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: #231f20;
  margin-bottom: 0;
}
.auction-info-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.auction-detail-cta {
  font-family: 'Roboto Condensed', sans-serif;
  border: 0px !important;
  vertical-align: top;
  font-size: 12px;
  border-color: #2170b8;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #2170b8 !important;
  color: white !important;
}

.action-menu-cta {
  vertical-align: top;
  background-color: transparent;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #2170b8;
  border-radius: 4px;
  margin-right: 15px;
}

.more-actions-icon {
  font-size: 24px;
}

.action-warning {
  font-size: 24px;
  color: #ef5869;
}

.action-description {
  margin-bottom: 5px;
  color: #231f20;
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
}

.auction-edit-items {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.auction-edit-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.auction-edit-field {
  background-color: #f4f5f8 !important;
  color: #231f20;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
}

.end-auction-modal .ant-modal-footer button:last-child {
  background: #ef5869;
  border-color: #ef5869;
}
