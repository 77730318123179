.auctionoverview .ant-table-thead > tr > th,
.auctionoverview .ant-table-tbody > tr > td,
.auctionoverview .ant-table tfoot > tr > th,
.auctionoverview .ant-table tfoot > tr > td {
  padding: 10px 10px 10px 0;
}
.auctionoverview-cta {
  padding: 9px 24px;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #2170b8;
  border-color: #2170b8;
  border-radius: 4px;
  color: #fff;
  margin-top: 5px;
}
.auctionoverview-cta:hover {
  background: #28a0f2;
  border-color: #28a0f2;
  color: #fff;
}

/* Auction Count Card */
.total-auction-count,
.total-auction-revenue {
  text-align: center;
  margin-top: 30px;
}
.total-auction-count-no {
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 65px;
  line-height: 65px;
  margin-bottom: 20px;
}
.total-auction-count-text,
.total-auction-revenue-text {
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
}

.total-auction-revenue-value {
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.auction-progress-bar {
  float: left;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 15px;
}
.auction-progress-bar-title {
  color: #fff;
  display: flex;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 5px;
}
.auction-progress-bar-title span {
  /* width: 33.33%; */
  color: #fff !important;
  line-height: 16px;
  margin-right: auto;
  margin-left: auto;
}
/* .ant-progress-inner{position: relative;} */
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #2170b8;
  border-radius: 0;
}
.ant-progress-inner {
  background-color: #0ebab3;
}

.total-auction-date {
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 15px;
}
.total-auction-date p {
  margin-bottom: 0;
  font-size: 15px;
  color: #fff;
  line-height: 15px;
  font-family: 'Roboto Condensed', sans-serif;
}

.total-auction-progress-bar {
  display: flex;
  width: 100%;
}

.open-auction-bar,
.draft-auction-bar,
.active-auction-bar {
  border-radius: 20px;
  position: relative;
  height: 10px;
  margin-right: 5px;
}
.active-auction-bar {
  margin-right: inherit;
}
.open-auction-bar {
  background: #2170b8;
}
.draft-auction-bar {
  background: #f86624;
}
.active-auction-bar {
  background: #0ebab3;
}

.open-auction-bar-h,
.draft-auction-bar-h,
.active-auction-bar-h {
  position: absolute;
  right: 0px;
  top: 2px;
  height: 20px;
  width: 4px;
  border-radius: 20px;
}
.total-auction-progress-bar .bar-number {
  margin-bottom: 0;
  bottom: -25px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  position: absolute;
  right: -1px;
}
.open-auction-bar .open-auction-bar-h {
  background: #2170b8;
}
.draft-auction-bar .draft-auction-bar-h {
  background: #f86624;
}
.active-auction-bar .active-auction-bar-h {
  background: #0ebab3;
}

.end-auction-cta {
  float: right;
  background-color: #ef5869;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #ef5869;
  border-radius: 4px;
}
.end-auction-cta:hover {
  background-color: #ef5869;
  color: #fff;
  border-color: #ef5869;
}
.auction-info .rscb-desc-cin {
  font-family: 'Roboto Condensed', sans-serif;
  color: #918f8f;
  margin-bottom: 10px;
  line-height: 16px;
}
.auction-info .rscb-desc-company {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #231f20;
  margin-bottom: 0;
}
.auction-info-cta {
  text-align: right;
}
.auction-detail-cta {
  vertical-align: top;
  background-color: transparent;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  border-color: #2170b8;
  border-radius: 4px;
  margin-right: 15px;
}

.end-auction-modal .ant-modal-footer button:last-child {
  background: #ef5869;
  border-color: #ef5869;
}

/* Auction Invite Graph */
.auction-invite-graph .apexcharts-datalabel-label {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: 700;
}
.auction-invite-legand-list {
  list-style: none;
  padding-left: 0;
}
.auction-invite-legand-list li {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  color: #231f20;
  margin-bottom: 10px;
}
.recvd-invite-legand {
  display: inline-block;
  width: 24px;
  height: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #dedede;
  border-radius: 20px;
  margin-right: 10px;
}
.active-auction-legand {
  display: inline-block;
  width: 24px;
  height: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  background: #0ebab3;
  border-radius: 20px;
  margin-right: 10px;
}
