.search-result-row {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  line-height: 64px;
  padding-left: 10px;
}
.search-result-row:last-child {
  border-bottom: none;
}
.search-result-row-left {
  display: inline-block;
  width: calc(100% - 80px);
  vertical-align: middle;
}
.search-result-row-right {
  display: inline-block;
  width: 80px;
  text-align: right;
}
.search-result-name {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.search-result-row-right .heading-cta {
  vertical-align: middle;
}
.search-result-name-sub {
  font-weight: 500;
}
.see-result-cta {
  vertical-align: middle;
  cursor: pointer;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  color: #2170b8;
  background-image: url('../../assets/images/icon-search-blue.png') !important;
  font-family: 'Roboto Condensed', sans-serif;
  background-position: left center;
  background-size: contain;
  padding-left: 25px;
  background-repeat: no-repeat;
  margin-top: 15px;
  margin-bottom: 0;
}
.all-search-result-box {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
  padding-top: 15px;
}
.all-search-result-box:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
