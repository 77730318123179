.CompanyProfile-edit .profile-img-info {
  margin-bottom: 35px;
}

.profile-row {
  font-family: 'Roboto Condensed', sans-serif;
}

.number-field::-webkit-inner-spin-button,
.number-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.add-seafood-list-button {
  font-size: 18px;
  line-height: 34px;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  border: 0px !important;
  padding: 0px;
  font-weight: 700;
  margin-left: 15px;
}

.seafood-item-actions {
  margin-left: 15px;
}
