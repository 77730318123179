.leaflet-popup-content p {
  margin: 2px !important;
}

.mark-arrived-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  border-color: #b7eb8f;
  background: #f6ffed;
  color: #52c41a;
}

.mark-arrived-button:focus {
  border-color: #b7eb8f;
  background: #f6ffed;
  color: #52c41a;
}
