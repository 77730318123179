.head-box-download {
  float: right;
  font-size: 14px;
  line-height: 34px;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  border: 0px !important;
  padding: 0px;
  font-weight: 700;
}

.head-box-download span {
  margin-right: 10px;
}

.specification-collapse.ant-collapse {
  background-color: transparent;
}

.specification-collapse .ant-collapse-header {
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  padding: 15px 16px 15px 0 !important;
  color: #231f20 !important;
  font-weight: 700;
}

.specification-collapse .ant-collapse-arrow {
  font-size: 18px !important;
}

.specification-items {
  list-style: none;
  padding-left: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.specification-collapse .left-item {
  width: 20%;
  display: inline-block;
}

.specification-collapse .right-item {
  width: 80%;
  display: inline-block;
}

.specification-items li {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 10px;
}

.specification-items li .left-item {
  color: #918f8f;
}

.specification-items li .right-item {
  color: #231f20;
}

.specification-collapse.ant-collapse-borderless
  > .ant-collapse-item:last-child {
  border-bottom: 0;
}
