.specification-input-item .ant-form-item-label label {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
}
.specification-input-item .specification-input {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #f4f5f8;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.specification-input-item .specification-input::placeholder {
  color: #231f20;
}
.specification-input-item .specification-input.ant-input-disabled {
  opacity: 0.5;
}
.specification-input-item .specification-tag-input .ant-select-selector {
  color: #231f20;
  padding-right: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #f4f5f8;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background-image: none !important;
}
.specification-input-item
  .specification-tag-input
  .ant-select-selector
  .ant-select-selection-search
  input {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif !important;
}
.specification-input-item
  .specification-tag-input
  .ant-select-selection-placeholder {
  color: #231f20;
}

.specification-tag-input .ant-select-arrow {
  color: #231f20;
}

.specification-input-item .specification-textarea-input {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
  background-color: #f4f5f8;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: 11px;
}
.specification-input-item .specification-textarea-input::placeholder {
  color: #231f20;
}
.specification-checbox-title {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
  display: inline-block;
  margin-right: 15px;
}
.specification-upload-area {
  padding: 4px 0;
  display: inline-block;
  position: relative;
  width: 100%;
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #f4f5f8;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.specification-upload-icon {
  width: 19px;
  height: 19px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.specification-input-item .ant-upload-list-text {
  display: inline-block;
  width: calc(100% - 24px);
}
.specification-input-item .ant-upload-list-text .ant-upload-list-item {
  margin-top: 0;
}
.specification-input-item .ant-upload-list-item-error,
.specification-input-item
  .ant-upload-list-item-error
  .ant-upload-text-icon
  > .anticon,
.specification-input-item
  .ant-upload-list-item-error
  .ant-upload-list-item-name {
  color: #231f20;
}

.specification-input-item .ant-space,
.specification-input-item .ant-space-item {
  width: 100%;
}
