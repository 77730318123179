.chart-area {
  width: 98%;
  display: inline-block;
  margin-top: 15px;
}
.sourcing-trend .historicaltrend-header {
  float: inherit;
}
.sourcing-trend-date-range {
  display: inline-block;
  margin-right: 15px;
}
.sourcing-trend-date-range .ant-picker-range {
  border-radius: 4px;
  background-color: #f4f5f8;
}
.sourcing-trend-date-range .ant-picker-range .ant-picker-input,
.sourcing-trend-date-range .ant-picker-decade-btn {
  font-family: 'Roboto Condensed', sans-serif;
}
.sourcing-trend
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 50%;
}
.sourcing-trend-date-range .ant-picker-active-bar {
  display: none;
}
.sourcing-trend-header-right {
  display: inline-block;
  float: right;
}
.sourcing-trend .apexcharts-legend-text {
  text-transform: capitalize;
}
.sourcing-trend .historicaltrend-header .toolbar {
  margin-right: 0;
}
.sourcing-trend .historicaltrend-header {
  margin-top: 20px;
}
