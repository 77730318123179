.request-changes-button {
  border-color: #0ebab3;
  background-color: #0ebab3;
  color: white;
}

.cancel-changes-button,
.reject-button {
  background: #ef5869;
  border-color: #ef5869;
}

.proforma-tabs .ant-tabs-content-holder {
  max-height: 100vh !important;
}

.proforma-approve-heading {
  font-size: 20px;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  width: 70%;
  display: inline-block;
}

.proforma-approve-btns {
  font-size: 20px;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  width: 30%;
  display: inline-block;
  text-align: right;
}

.proforma-actions {
  text-align: right;
}
