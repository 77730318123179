.horizontal-timeline .ant-steps-item-title {
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
}

.horizontal-timeline .ant-steps-item-active .ant-steps-item-icon {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.horizontal-timeline .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0ebab3 !important;
  border-color: #0ebab3 !important;
}

.horizontal-timeline
  .ant-steps-item-active
  .ant-steps-item-icon
  > .ant-steps-icon,
.horizontal-timeline
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #fff !important;
}

.horizontal-timeline
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #0ebab3 !important;
}

.horizontal-timeline
  .ant-steps:not(.ant-steps-vertical)
  .ant-steps-item-custom
  .ant-steps-item-icon {
  width: 32px !important;
}

.horizontal-timeline
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  font-size: 16px !important;
}
