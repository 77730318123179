.update-role-chat {
  display: inline-block;
  padding: 0;
  height: 27px;
  border: 0;
}
.update-role-chat:hover {
  background: none;
}
.member-role .ant-tag {
  font-size: 10px;
  border-radius: 4px;
  font-weight: 400;
  text-transform: capitalize;
  background-color: transparent;
  border-color: #0ebab3;
  color: #0ebab3;
}
.member-role .ant-tag.ant-tag-has-color {
  color: #fff;
}
.member-pic {
  width: 40px;
  height: 40px;
}
.member-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
