.review-selection-detail {
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 5px;
}
.review-selection-title,
.modal-title {
  margin-bottom: 5px;
  color: #231f20;
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}
.review-selection-tble .ant-table-thead > tr > th {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}

.review-selection-tble .ant-table-tbody > tr > td:first-child {
  color: #918f8f;
}
.review-selection-tble .ant-table-tbody > tr > td,
.review-selection-tble .ant-table-thead > tr > th {
  padding: 5px 5px 5px 0;
}

.ant-modal .ant-modal-footer button {
  font-size: 16px;
  line-height: 0px;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 4px;
  /* padding: 20px; */
  height: 40px;
}
.ant-modal .ant-modal-footer button:last-child {
  float: left;
  margin-left: 0;
  margin-right: 15px;
  background: #2170b8;
  color: #fff;
}
.ant-modal .ant-modal-footer button:first-child {
  border-color: #2170b8;
  color: #2170b8;
}
.ant-modal .ant-modal-body {
  padding: 0;
}
.ant-modal .ant-modal-content {
  padding: 20px;
  border-radius: 12px;
}
.ant-modal .ant-modal-footer {
  border-top: 0;
  text-align: left;
  padding: 25px 0 0;
}

.select-quantity .ant-table-tbody > tr > td,
.select-quantity .ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0;
}
.select-quantity-input .input-field {
  background-color: #f4f5f8 !important;
  color: #231f20;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.select-quantity-input .input-field::placeholder {
  color: #231f20;
}
.bid-modal-content {
  font-size: 20px;
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
}
.bid-cont-img,
.bid-modal-content {
  text-align: center;
}
.single-btn-center-footer .ant-modal-footer button:last-child {
  display: none;
}
.single-btn-center-footer .ant-modal-footer {
  text-align: center;
  padding: 10px 0 0;
}
.single-btn-center-footer .ant-modal-footer button:first-child {
  border-color: #2170b8;
  background-color: #2170b8;
  color: #fff;
}

.single-btn-footer .ant-modal-footer button:last-child {
  display: none;
}
.single-btn-footer .ant-modal-footer {
  padding: 10px 0 0;
}
.single-btn-footer .ant-modal-footer button:first-child {
  border-color: #2170b8;
  background-color: #2170b8;
  color: #fff;
}

.modal-description {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.reject-bid-modal .ant-modal-footer button:last-child {
  background: #ef5869;
  border-color: #ef5869;
}

.confirmation-modal-negative .ant-modal-footer button:last-child {
  background: #ef5869;
  border-color: #ef5869;
}

.reject-bid-modal .ant-modal-footer button:last-child:hover,
.confirmation-modal-negative .ant-modal-footer button:last-child:hover {
  background: #ea1f37 !important;
  border-color: #ea1f37 !important;
}

.confirmation-modal .ant-modal-footer {
  padding: 10px 0 0;
}

.ant-modal .ant-modal-footer button:last-child:disabled {
  background-color: #a9a9a9;
}

.create-proforma-modal .ant-modal-body {
  height: 800px;
  overflow-y: scroll;
}

.invite-member-form .ant-form-item,
.update-user-role-form .ant-form-item {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.invite-member-form .ant-form-item-label,
.update-user-role-form .ant-form-item-label {
  text-align: left;
  width: 150px;
}

.invite-member-field {
  background-color: #f4f5f8 !important;
  color: #231f20;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
}

.full-height-modal .ant-modal-content {
  height: 90vh;
}

.full-height-modal .ant-modal-body {
  height: 80vh;
}

.add-cert-btn {
  float: right;
  font-size: 14px;
  line-height: 34px;
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  border: 0px !important;
  padding: 0px;
  font-weight: 700;
}

.remove-cert-btn {
  float: right;
  font-size: 14px;
  line-height: 34px;
  color: #ea1f37;
  font-family: 'Roboto Condensed', sans-serif;
  border: 0px !important;
  padding: 0px;
  font-weight: 700;
}
