.bid-charts-tabs {
  min-height: 500px;
}

.auction-tabs.notification-alerts .ant-tabs-tab-btn {
  font-size: 20px;
}
.pai-section {
  position: relative;
}
.auction-tab-seacrh .plordfull-search {
  margin-right: 0;
}
.auction-tab-seacrh {
  position: absolute;
  top: 28px;
  right: 24px;
}
.seller-clickable {
  cursor: pointer;
  color: #2170b8;
}
.seller-bid-sub {
  width: 40px;
  margin-left: 10px;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  background: #0ebab3;
  /* padding: 5px 16px; */
  border-radius: 15px;
  line-height: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  line-height: 20px;
}
.auction-tab-table .ant-table-tbody > tr > td,
.auction-tab-table2 .ant-table-tbody > tr > td,
.auction-tab-table3 .ant-table-tbody > tr > td {
  padding: 18px 5px 18px 5px;
  margin-left: 5px;
}
.auction-tab-table .ant-table-tbody tr td:nth-child(5),
.auction-tab-table .ant-table-tbody tr td:nth-child(6),
.auction-tab-table2 .ant-table-tbody tr td:nth-child(4),
.auction-tab-table2 .ant-table-tbody tr td:nth-child(5) {
  color: #36b44c;
}
.auction-tab-table .ant-table-tbody tr td:nth-child(8) {
  color: #0ebab3;
}
.participant-tab {
  position: relative;
}
.participant-tab .lb-denote {
  float: right;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 10px;
}
.participant-tab .lb-denote-div {
  text-align: right;
  margin-top: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 10px;
  font-weight: bold;
}
.participant-tab .lb-denote .seller-bid-sub {
  margin-right: 10px;
}
.negative-savings {
  color: #e10f0f;
}
.positive-savings {
  color: #0ebab3;
}
