.incoterms {
  position: relative;
  padding: 65px 5px 20px 5px;
  border: 2px solid #2170b8;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: center 20px;
  margin-top: 15px;
  margin-right: 15px;
  line-height: 16px;
  text-align: center;
  width: 130px;
  justify-content: center !important;
}
.incoterm-cnf-light {
  background-image: url('../../assets/images/cnf-dark.png');
}
.incoterm-cnf-dark {
  background-image: url('../../assets/images/cnf-light.png');
}
.incoterm-cy-light {
  background-image: url('../../assets/images/con-yard-dark.png');
}
.incoterm-cy-dark {
  background-image: url('../../assets/images/con-yard-light.png');
}

.incoterms-dark {
  background-color: #2170b8;
}
.incoterms .ant-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}
.incoterms span:last-child {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #2170b8;
  padding: 0;
}
.incoterms-dark span:last-child {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  padding: 0;
}
.incoterms .ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  background: #fff;
  border: 2px solid #2170b8;
  width: 18px;
  height: 18px;
}
.incoterms-dark .ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  background: #fff !important;
  border: 2px solid #fff;
  width: 18px;
  height: 18px;
}

.incoterms .ant-checkbox:hover::after,
.incoterms.ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: 0;
}

.estimate-pop {
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
}
.estimate-pop .anticon {
  margin-left: 10px;
  cursor: pointer;
}

.estimate-tooltip {
  display: inline-block;
  color: #231f20;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0px;
}
.ant-tooltip-arrow-content {
  background-color: #fff;
}
.ant-tooltip-inner {
  background-color: #fff;
  color: #918f8f;
  font-family: 'Roboto Condensed', sans-serif;
  padding: 10px 15px;
  line-height: 16px;
  box-shadow: 0px 8px 20px #0000001a;
}

.incoterms .ant-checkbox-wrapper {
  display: inline-flex !important;
  justify-content: center !important;
}

.incoterms .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #2170b8 !important;
}
