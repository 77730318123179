.profile-img-info {
  width: 100%;
  margin-bottom: 20px;
}
.profile-img {
  width: 120px;
  height: 120px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.profile-img img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}
.profile-info {
  padding: 17px 22px;
  width: calc(100% - 120px);
  display: inline-block;
  vertical-align: middle;
}
.profile-username {
  color: #2170b8;
  font-size: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  margin-bottom: 0;
}
.pro-designation-mailid {
  font-family: 'Roboto Condensed', sans-serif;
  color: #231f20;
}
.profile-company-name {
  display: inline-block;
  background-color: #231f20;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
}

.userprofile .ant-picker-input,
.userprofile .ant-picker {
  cursor: pointer;
}
.editprofile-btn-area {
  text-align: right;
  display: inline-block;
  width: 100%;
}
.ant-upload-select-text {
  margin-top: 8px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.ant-upload-select-text span {
  color: #2170b8;
}
.userprofile-edit .profile-img-info {
  margin-bottom: 35px;
}

.change-btn {
  color: #2170b8;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.pass-chnge-box span {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.pass-chnge-box {
  position: relative;
}
