.step-nav-btn {
  padding: 10px 20px;
  vertical-align: top;
  background-color: #2170b8;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  line-height: 23px;
  border-color: #2170b8;
  border-radius: 4px;
  height: 44px;
}
.steps-action {
  text-align: center;
}
/* for ideal step */
.carva-step-form .ant-steps-item-container {
  background-color: #dcdcdc;
  display: inline-block;
  border-radius: 8px;
  padding: 5px 15px;
}
.carva-step-form .ant-steps-item-title::after {
  height: 2px;
  background: #d3d3d3 !important;
  left: 112%;
}
.carva-step-form
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding-left: 0;
}
.carva-step-form .ant-steps-item-title {
  font-family: 'Roboto Condensed', sans-serif;
  padding-right: inherit;
}
.carva-step-form
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #615d5e;
}
.carva-step-form
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: #615d5e;
  font-size: 16px;
}
.carva-step-form .steps-content {
  padding: 30px 0 15px 0;
}
/* for ideal step end */
/* for progress step */
.carva-step-form .ant-steps-item-process .ant-steps-item-container {
  background-color: #0ebab3;
}
.carva-step-form
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}
.carva-step-form
  .ant-steps-item-process.ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: #fff;
}
/* for progress step end */
/* for finish step */
.carva-step-form .ant-steps-item-finish .ant-steps-item-container {
  background-color: #0ebab3;
  background-image: url('../../../../../assets/images/check-white.png');
  background-repeat: no-repeat;
  background-position: 15px 13px;
  background-size: 18px;
}
.carva-step-form
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
  padding-left: 18px;
}
.carva-step-form
  .ant-steps-item-finish.ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  display: none;
}
.carva-step-form .ant-steps-item-finish .ant-steps-item-title::after {
  background: #0ebab3 !important;
}

/* finish step end */
