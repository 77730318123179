.chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.chat-header {
  position: relative;
}

.chat-header .str-chat__header-livestream p {
  font-family: 'Roboto Condensed', sans-serif !important;
}

.str-chat {
  height: 90vh !important;
}

.str-chat__channel-search {
  padding: 0px 10px !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0px 0px 0px 0px !important;
}

.str-chat-channel-list .str-chat__channel-list-messenger__main {
  height: 95% !important;
}

.str-chat__send-button {
  display: block !important;
  cursor: pointer;
}

#chat-info {
  position: absolute;
  right: 5px;
  top: 10px;
  font-family: 'Roboto Condensed', sans-serif !important;
  cursor: pointer;
  z-index: 1000;
  border: none;
  box-shadow: none;
  color: #2170b8;
}

#chat-info:hover,
#chat-info:active,
#chat-info:focus {
  background-color: inherit;
  color: #2170b8 !important;
}

#chat-info .anticon {
  font-size: 19px;
}

.info-card {
  padding: 0px !important;
  max-width: 33.3%;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  width: 350px !important;
}

.info-header {
  padding: 20px;
  height: 70px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.info-header-title {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
}

.info-header-action {
  cursor: pointer;
}

.info-items {
  padding: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  list-style-type: none;
}

.info-left-item {
  font-weight: bold;
}

.info-right-item {
  text-align: right;
}

.str-chat__channel-preview-messenger--unread-count {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #25d366;
  background-color: #25d366;
  color: #fff;
  text-align: center;
  float: right;
}

.str-chat__channel-search .ant-select {
  width: 100%;
}

.str-chat__small-message-input-emojipicker {
  right: -40px;
}

@media screen and (max-width: 1210px) {
  .info-left-item,
  .info-right-item {
    text-align: center;
  }
}
